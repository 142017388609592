import React from 'react';
import './Footer.css';

const Footer = () => {

  
  return (
    
<body>
  <footer class="footer">
    
    <ul class="menu">
      <li class="menu__item"><a class="menu__link" href="#">Home</a></li>
      <li class="menu__item"><a class="menu__link" href="#">About</a></li>
      <li class="menu__item"><a class="menu__link" href="#">Comics</a></li>
      <li class="menu__item"><a class="menu__link" href="#">Form</a></li>

    </ul>
    <p>&copy;2024 East Park Comics | All Rights Reserved</p>
  </footer>
  <script type="module" src="https://unpkg.com/ionicons@5.5.2/dist/ionicons/ionicons.esm.js"></script>
  <script nomodule src="https://unpkg.com/ionicons@5.5.2/dist/ionicons/ionicons.js"></script>
</body>
  );
};

export default Footer;
